import { CourseFormInput } from 'types/courseTypes/CourseFormInput';

export const getCourseTypeName = (courseTypeOptions: any[], id?: number) => {
  return courseTypeOptions.find((item: any) => item.value === id)?.label;
};

export const filterCourseCategoryOptions = (
  isAssessment: boolean,
  courseTypeOptions: any[]
) => {
  const labels = ['Industry Principles', 'Cultivation', 'Extraction'];

  return courseTypeOptions.filter((item: any) =>
    isAssessment ? labels.includes(item.label) : !labels.includes(item.label)
  );
};

export const filterPreRequisiteOptions = (
  isAssessment: boolean,
  preRequisiteOptions: any[],
  courseTypeId?: number
) => {
  if (isAssessment) {
    return preRequisiteOptions.filter(
      (item: any) => item.typeId === courseTypeId || item.value === ''
    );
  }
  return preRequisiteOptions;
};

export const formatCourseFormValues = (values: CourseFormInput) => {
  const {
    successMessage,
    firstFailureMessage,
    secondFailureMessage,
    thirdFailureMessage,
    fourthFailureMessage,
    certificateId,
    prerequisiteId,
    rewardId,
    organizationId,
    firstCourseRoundAttempts,
    lockoutPeriod,
    secondCourseRoundAttempts,
    rewardTypeId,
    rewardTypeName,
    swagName,
    swagType,
    swagCompletionThreshold,
    sampleName,
    sampleType,
    sampleCompletionThreshold,
    contestName,
    isRaffleActive,
    expiresOn,
    emailBlastDate,
    socialMediaBlastDate,
    outReachStates,
    outReachActivationDescription,
    startActivationDate,
    outReachDaysActive,
    rewardAssigned,
    hasCommunicationSupport,
    isRevision,
    assessmentId,
    externalCourseId,
    courseBuilderId,
    dateFirstPublished,
    isSwagActive,
    swagExpiresOn,
    isSampleActive,
    sampleExpiresOn,
    ...otherValues
  } = values;

  let courseIncentive;
  if (rewardAssigned) {
    courseIncentive = {
      name: contestName || '',
      rewardTypeId,
      completionThreshold: 0,
      type: '',
      isActive: isRaffleActive === 'yes',
      expiresOn: expiresOn,
    };

    if (rewardTypeName === 'Swag') {
      courseIncentive = {
        ...courseIncentive,
        name: swagName || '',
        type: swagType || '',
        completionThreshold: swagCompletionThreshold || 0,
        isActive: isSwagActive === 'yes',
        expiresOn: swagExpiresOn,
      };
    } else if (rewardTypeName === 'Sample') {
      courseIncentive = {
        ...(courseIncentive || ''),
        name: sampleName || '',
        type: sampleType || '',
        completionThreshold: sampleCompletionThreshold || 0,
        isActive: isSampleActive === 'yes',
        expiresOn: sampleExpiresOn,
      };
    }
  }

  let courseCommunication;
  if (hasCommunicationSupport === 'yes') {
    courseCommunication = {
      emailBlastDate,
      socialMediaBlastDate,
    };
  }

  let courseOutreach;
  if (
    outReachActivationDescription ||
    startActivationDate ||
    outReachDaysActive ||
    outReachStates
  ) {
    courseOutreach = {
      description: outReachActivationDescription,
      startDate: startActivationDate,
      daysActive: outReachDaysActive,
      courseOutreachStates:
        outReachStates.map((outReachState) => {
          return {
            state: outReachState,
          };
        }) || [],
    };
  }

  const courseRounds = [
    {
      attempts: firstCourseRoundAttempts,
      lockoutPeriod: lockoutPeriod,
      roundNumber: 1,
    },
    {
      attempts: secondCourseRoundAttempts,
      roundNumber: 2,
    },
  ];

  const courseMessage = {
    successMessage,
    firstFailureMessage,
    secondFailureMessage,
    thirdFailureMessage,
    fourthFailureMessage,
  };

  return {
    ...otherValues,
    dateFirstPublished: isRevision ? dateFirstPublished : new Date(),
    isRevision: isRevision.toString() == 'true',
    courseRounds: validateCourseRoundsValues(
      firstCourseRoundAttempts,
      secondCourseRoundAttempts,
      lockoutPeriod
    )
      ? courseRounds
      : [],
    certificateId: certificateId || null,
    prerequisiteId: prerequisiteId || null,
    rewardId: rewardId || null,
    organizationId: organizationId || null,
    externalCourseId: assessmentId || externalCourseId || courseBuilderId || '',
    courseMessage,
    courseIncentive,
    courseCommunication,
    courseOutreach,
  };
};

export const formatCourseFormValuesOnCreation = (values: CourseFormInput) => {
  const formattedValue = formatCourseFormValues(values);

  // Check if any message exists
  const anyMessageExists = Object.values(formattedValue.courseMessage).some(
    (message) => message
  );

  return anyMessageExists
    ? formattedValue
    : { ...formattedValue, courseMessage: undefined };
};

export const validateCourseRoundsValues = (
  firstCourseRoundAttempts: number | undefined,
  secondCourseRoundAttempts: number | undefined,
  lockoutPeriod: number | undefined
) => {
  return (
    (firstCourseRoundAttempts !== undefined &&
      firstCourseRoundAttempts !== null) ||
    (secondCourseRoundAttempts !== undefined &&
      secondCourseRoundAttempts !== null) ||
    (lockoutPeriod !== undefined && lockoutPeriod !== null)
  );
};

export const getRewardTypeName = (rewardTypesOptions: any[], id?: string) => {
  return rewardTypesOptions.find((item: any) => item.value === id)?.label;
};
